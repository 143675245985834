import { DataGrid } from '@mui/x-data-grid';
import './App.css';

import React from 'react';
import { useEffect, useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import SchoolMap from './SchoolMap';


function App() {

  const [schools, setSchools] = useState([]);
  const [columns, setColumns] = useState([]);
  const [filteredSchools, setFilteredSchools] = useState([]);

  console.log('fetching schools');

  useEffect(() => {
    console.log('fetching filtered schools');
    fetch('/listagps.json')
      .then(response => response.json())
      .then(rows => {
        setFilteredSchools(rows.map(row => row.code));
      })
    }, []);

  useEffect(() => {
    const url = '/scuole_corretto2.json'
    fetch(url)
      .then(response => response.json())
      .then(rows => {
        // aggiungi la colonna id prendendola da CODICESCUOLA
        const schools = rows.map((row, index) => {
          return { ...row, id: row.CODICESCUOLA };
        });

        // filtra le scuole da filteredSchools
        const schoolsFiltered = schools.filter(school => {
          return filteredSchools.includes(school.CODICESCUOLA);
        });

        const filteredSchoolsCount = filteredSchools.length;
        const schoolsFilteredCount = schoolsFiltered.length;
        console.log('scuole filtrate', filteredSchoolsCount);
        console.log('schoolsFilteredCount', schoolsFilteredCount);
        if (filteredSchoolsCount !== schoolsFilteredCount) {
          console.log('Attenzione: scuole filtrate', filteredSchoolsCount, 'diverse da scuole filtrate', schoolsFilteredCount);
        }


        setSchools(schoolsFiltered);
        // crea le colonne
        const columns = Object.keys(schools[0]).map((key, index) => {
          return { field: key, headerName: key, width: 200 };
        });
        setColumns(columns);
      })
      .catch(error => console.error(error));
  }, [filteredSchools]);
  const defaultTheme = createTheme();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

          <Grid item xs={12}>
            <Container fixed style={{height: '500px'}}>
              <SchoolMap schools={schools} />
              </Container>
          </Grid>

          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <DataGrid checkboxSelection rows={schools} columns={columns} />
            </Paper>
          </Grid>

        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;

import * as React from 'react';
import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'
import { Marker } from 'react-leaflet/Marker'
import { Popup } from 'react-leaflet/Popup'
import "leaflet/dist/leaflet.css";
import Leaflet from 'leaflet'
import {useRef} from 'react';

import { Icon } from "leaflet";
import MarkerIcon2X from "leaflet/dist/images/marker-icon-2x.png";
import MarkerIcon from "leaflet/dist/images/marker-icon.png";
import MarkerShadow from "leaflet/dist/images/marker-shadow.png";

Icon.Default.mergeOptions({
	iconRetinaUrl: MarkerIcon2X,
	iconUrl: MarkerIcon,
	shadowUrl: MarkerShadow,
});


function SchoolMap(props) {

  const map = useRef(null);

  const markers = props.schools.map(school => {
    const position = school.coordinate.split(',').map( (coord) => parseFloat(coord));
    if (position.length !== 2) {
      return null;
    }

    return (
      <Marker key={school.id} position={position}>
        <Popup>
          {school.DENOMINAZIONESCUOLA}
          <br />
          <a href={`https://www.google.com/maps/search/?api=1&query=${school.indirizzo}`} target="_blank" rel="noreferrer">Google Maps indirizzo</a>
          <br />
          <a href={`https://www.google.com/maps/search/?api=1&query=${position[0]},${position[1]}`} target="_blank" rel="noreferrer">Google Maps coordinate</a>
          <br />
          <a href={`https://cercalatuascuola.istruzione.it/cercalatuascuola/ricerca/risultati?rapida=${school.CODICESCUOLA}&tipoRicerca=RAPIDA&gidf=1`} target="_blank" rel="noreferrer">Informazioni ministero</a>
        </Popup>
      </Marker>
    )
  }).filter(marker => marker !== null);
  const center = [45.079401770353435, 7.683104152017823];

  return (
    <MapContainer zoom={13} style={{
      height: "100%",
      width: "100%",
    }} whenCreated={(map) => { setTimeout(() => map.invalidateSize(), 100); }} center={center}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {markers}
    </MapContainer>
  );
}

export default SchoolMap;